<template>
  <div id='links'>
    <PageTitle text='リンク集' />
    <SectionTitle icon='mdi-heart' text='関連サイト' />
    <LinkList :teams='relatedLinks' />
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import SectionTitle from '@/components/SectionTitle'
import LinkList from '@/components/LinkList'

import { relatedLinks } from '@/config/links'

export default {
  components: { PageTitle, SectionTitle, LinkList },
  data () {
    return { relatedLinks }
  },
}
</script>
